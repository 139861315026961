import { hasPermission } from "../Permissions";
import { mapGetters } from 'vuex'

export default {
  methods: {
    hasAccess(area, action) {
      if (this.user.profile) {
        return hasPermission(area, action, this.user.profile.role);
      }
      return false;
    },
    },
    computed: {
        ...mapGetters(['user']),
        isSuperAdmin() {
            return this.user.profile && this.user.profile.role === 'superadmin'
        }
    }
};